<template>
	<v-sheet class="order" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="3" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="true"
					:items="listingStatusArr"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.order_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="9" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-layout class="justify-end">
						<div class="mx-1" style="max-width: 300px">
							<date-range-picker
								v-on:click:clear="
									search['date-range'] = [];
									searchOrders();
								"
								hide-details
								hide-top-margin
								clearable
								v-model="search['date-range']"
							></date-range-picker>
						</div>
						<div class="mx-1" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									search['transaction-type'] = [];
									searchOrders();
								"
								clearable
								:items="transaction_items"
								hide-details
								custom-class="pt-0"
								placeholder="Transactions Type"
								multiple
								v-model="search['transaction-type']"
							></select-input>
						</div>
						<div class="mx-1" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									search['item-type'] = [];
									searchOrders();
								"
								clearable
								:items="item_type_items"
								hide-details
								custom-class="pt-0"
								placeholder="Item Type"
								multiple
								v-model="search['item-type']"
							></select-input>
						</div>
						<div class="mx-1" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									search['incharge-officer'] = [];
									searchOrders();
								"
								clearable
								:items="incharge_items"
								hide-details
								custom-class="pt-0"
								placeholder="Staff"
								multiple
								v-model="search['incharge-officer']"
							></select-input>
						</div>
						<div class="d-flex">
							<v-btn
								color="blue darken-4 white--text"
								:disabled="pageLoading"
								:loading="pageLoading"
								depressed
								tile
								v-on:click="searchOrders()"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
							<v-menu
								bottom
								left
								origin="center center"
								transition="slide-y-transition"
								rounded="0"
								offset-y
								max-height="400px"
								:close-on-content-click="false"
								content-class="white-background"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="blue darken-4"
										class="text-white"
										tile
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-table-edit</v-icon>
									</v-btn>
								</template>
								<Draggable
									tag="ul"
									v-model="draggableThead"
									class="draggable-group"
									handle=".draggable-drag-icon"
									v-on:change="updateTable('order')"
								>
									<template v-for="cols in draggableThead">
										<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
											<v-checkbox
												dense
												v-model="draggableTheadShow"
												v-bind:value="cols.key"
												:label="cols.name"
												:disabled="cols.fixed || pageLoading"
												color="blue"
												hide-details
												class="mt-0 mb-0"
												v-on:change="updateTableVisiblity('order')"
											></v-checkbox>
											<v-icon
												v-if="!pageLoading && !cols.fixed"
												class="draggable-action draggable-drag-icon"
												right
												color="blue"
												>mdi-drag</v-icon
											>
											<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
										</li>
									</template>
								</Draggable>
							</v-menu>
						</div>
					</v-layout>
					<table width="100%" v-if="false" style="table-layout: fixed">
						<tr>
							<td class="px-2">
								<date-range-picker
									v-on:click:clear="
										search['date-range'] = [];
										searchOrders();
									"
									hide-details
									hide-top-margin
									clearable
									v-model="search['date-range']"
								></date-range-picker>
							</td>
							<td class="px-2">
								<select-input
									v-on:click:clear="
										search['transaction-type'] = [];
										searchOrders();
									"
									clearable
									:items="transaction_items"
									hide-details
									custom-class="pt-0"
									placeholder="Transactions Type"
									multiple
									v-model="search['transaction-type']"
								></select-input>
							</td>
							<td class="px-2">
								<select-input
									v-on:click:clear="
										search['item-type'] = [];
										searchOrders();
									"
									clearable
									:items="item_type_items"
									hide-details
									custom-class="pt-0"
									placeholder="Item Type"
									multiple
									v-model="search['item-type']"
								></select-input>
							</td>
							<td class="px-2">
								<select-input
									v-on:click:clear="
										search['incharge-officer'] = [];
										searchOrders();
									"
									clearable
									:items="incharge_items"
									hide-details
									custom-class="pt-0"
									placeholder="Staff"
									multiple
									v-model="search['incharge-officer']"
								></select-input>
							</td>
							<td width="17%" align="right" class="px-2 d-flex">
								<v-btn
									color="blue darken-4 white--text"
									:disabled="pageLoading"
									:loading="pageLoading"
									depressed
									tile
									v-on:click="searchOrders()"
								>
									<v-icon>mdi-magnify</v-icon> Search
								</v-btn>
								<v-menu
									bottom
									left
									origin="center center"
									transition="slide-y-transition"
									rounded="0"
									offset-y
									max-height="400px"
									:close-on-content-click="false"
									content-class="white-background"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="blue darken-4"
											class="text-white"
											tile
											:disabled="pageLoading"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>mdi-table-edit</v-icon>
										</v-btn>
									</template>
									<Draggable
										tag="ul"
										v-model="draggableThead"
										class="draggable-group"
										handle=".draggable-drag-icon"
										v-on:change="updateTable('order')"
									>
										<template v-for="cols in draggableThead">
											<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
												<v-checkbox
													dense
													v-model="draggableTheadShow"
													v-bind:value="cols.key"
													:label="cols.name"
													:disabled="cols.fixed || pageLoading"
													color="blue"
													hide-details
													class="mt-0 mb-0"
													v-on:change="updateTableVisiblity('order')"
												></v-checkbox>
												<v-icon
													v-if="!pageLoading && !cols.fixed"
													class="draggable-action draggable-drag-icon"
													right
													color="blue"
													>mdi-drag</v-icon
												>
												<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
											</li>
										</template>
									</Draggable>
								</v-menu>
							</td>
						</tr>
					</table>
				</template>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12">
				<v-layout class="page-summary show">
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="blue">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">All Records</div>
						<div class="number red--text">{{ orderCount.allOders }}</div>
					</v-flex>
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="green">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Pending Documents</div>
						<div class="number red--text">{{ orderCount.pendingOrders }}</div>
					</v-flex>

					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="red">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Issued</div>
						<div class="number red--text">{{ orderCount.issueOrders }}</div>
					</v-flex>
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="red">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Received</div>
						<div class="number red--text">{{ orderCount.reciveOrder }}</div>
					</v-flex>
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="red">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Retired</div>
						<div class="number red--text">{{ orderCount.retiredOrders }}</div>
					</v-flex>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Order #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="order"
			delete-endpoint="order/"
			detail-route="order-detail"
			update-route="order-update"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this order will also be deleted."
		></Table>
		<ExportDialog
			endpoint="order/export?type=order&current=1"
			title="Orders"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="order/export?type=order"
			title="Orders"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="order/import?type=order"
			title="Orders"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Orders"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import DateRangePicker from "@/view/components/DateRangePicker";
import SelectInput from "@/view/components/SelectInput";
import { mapGetters } from "vuex";
import ObjectPath from "object-path";
import { isString, toSafeInteger, map } from "lodash";
import ApiService from "@/core/services/api.service";

export default {
	name: "order-listing",
	title: "Listing Order",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Records",
			orderCount: [],
			listingStatusArr: [
				{
					id: 10,
					type: "order",
					text: "All Records",
					value: "all",
					description: "List of All Records",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			search: {
				"date-range": [],
				"transaction-type": [],
				"item-type": [],
				"incharge-officer": [],
			},
			transaction_items: [
				{ text: "Issue", value: "issue" },
				{ text: "Receive", value: "receive" },
				{ text: "Retire", value: "retire" },
			],
			item_type_items: [
				{ text: "Asset", value: "asset" },
				{ text: "Asset Stock", value: "asset-stock" },
				{ text: "Perishable", value: "perishable" },
			],
			incharge_items: [],
			pageBreadcrumbs: [{ text: "Records", disabled: true }],
			endpoint: "orders",
			defaultFilter: {},
		};
	},
	components: {
		SelectInput,
		DateRangePicker,
	},
	methods: {
		getOrdersCount() {
			ApiService.setHeader();
			ApiService.get(`order-count`)
				.then(({ data }) => {
					this.orderCount = data;
				})
				.catch(() => {});
		},
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
	},
	mounted() {
		this.incharge_items = this.localDB("members", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.search["date-range"] = [date_range];
		} else {
			this.search["date-range"] = date_range;
		}

		const transaction_type = ObjectPath.get(this.$route, "query.transaction-type", []);
		if (isString(transaction_type)) {
			this.search["transaction-type"] = [toSafeInteger(transaction_type)];
		} else {
			this.search["transaction-type"] = map(transaction_type, (row) => {
				return toSafeInteger(row);
			});
		}

		const item_type = ObjectPath.get(this.$route, "query.item-type", []);
		if (isString(item_type)) {
			this.search["item-type"] = [item_type];
		} else {
			this.search["item-type"] = item_type;
		}

		const incharge_officer = ObjectPath.get(this.$route, "query.incharge-officer", []);
		if (isString(incharge_officer)) {
			this.search["incharge-officer"] = [toSafeInteger(incharge_officer)];
		} else {
			this.search["incharge-officer"] = map(incharge_officer, (row) => {
				return toSafeInteger(row);
			});
		}
		this.getOrdersCount();
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
